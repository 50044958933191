@import "../common.scss";

#SearchForm {
    text-align: center;
    form#ricerca {
        align-items: center;
        display: flex;
        gap: $g05;

        .sf--input-option-bg {
            display: flex;
            gap: $g05;
            position: relative;
            background-color: white;
            border-radius: $border-radius;
            padding: $g05;
            &.with-purpose {
                border-radius: $border-radius $border-radius 0 0;
            }
        }

        #champ {
            border: none;
            border-radius: $border-radius;
            height: $l;
            // width: 60vw;
            max-width: 500px;
            padding: 0 $g10;

            &:focus-visible {
                outline: none;
            }
        }

        .btn-validate {
            flex-grow: 10;
            flex-shrink: 10;
            height: $xxl;
            aspect-ratio: 1/1;

            i {
                font-size: 2rem;
            }
            &:hover {
                background-color: $primary-hover;
            }
        }
    }

    @media (max-width: $mobile-size) {
        form#ricerca {
            align-items: stretch;

            #champ {
                width: auto;
            }
            flex-direction: column;
        }
    }

    &.sf--header {
        form#ricerca {
            align-items: center;
            .sf--input-option-bg {
                padding: 0;
                #champ {
                    width: 90px;
                }
                #SearchOptionDropDownMenu {
                    width: 20px;
                    margin-right: $g05;
                }
            }
            flex-direction: row;
            
            button.btn-validate {
                height: 56px;
            }
        }
    }
    @media (max-width: $mobile-size) {
        &.sf--header {
            form#ricerca {
                .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon {
                    min-width: auto;
                }
                button.btn-validate {
                    //height: 40px;
                    i {
                        font-size: 1.5rem;
                    }
                }
                .option-form {
                    display: none;
                }
            }
        }
    }
}
