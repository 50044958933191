@import '../common.scss';

.NotFoundPage {
    position: fixed;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;


    .p404--button {
        width: 30%;
        height: 50px;
        border-radius: $border-radius;
        color: white;
        border: none;
        background-color: $primary;
        cursor: pointer;
    }
}