@import "../common.scss";


.Homepage {
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        text-align: center;
        margin: 64px 0;
    }

    div.hp-logo {
        text-align: center;
        display: flex;
        max-width: 100%;
        gap: $g05;

        .hp-logo-image {
            max-width: 35vw;
        }

        @media (max-width: $mobile-size) {
            display: block;
            .hp-logo-image {
                max-width: 70vw;
            }
        }
    }
}
