@import "../common.scss";

.EditLemmePage {
    padding: $l; 

    .el--header {
        text-align: center;
    }

    .el--lemme {
        margin-bottom: $g15;
    }


    .el--main {
        display: flex;
        flex-direction: column;
        gap: $g05;

    }

    .el--buttons {
        margin-top: $g15;
        display: flex;
        justify-content: center;
        gap: $g15;
    }

}
