

// COLORIMETRIE
$dark-bg: #221f20;
$light-bg: #f2f2f2;
$primary: #bf623a;
$text-primary: #fff;
$secondary: #bf623a;
$text-secondary: #fff;
$primary-hover: #8badd9;
$primary-light: #e8edf6;


// NAVBAR
$nav-bg: #fff;
$nav-bg-hover: #f4f4f4;
$link-color: black;

// TYPOGRAPHIE
$main-font: Helvetica, sans-serif;
$sm-font: 0.75rem;


// AUTRES
$border-radius: 10px;


/*       
    SPACINGS
*/
// Taille des gouttières
$g05    : 8px;
$g10    : 16px;
$g15    : 24px;
$g20    : 32px;

// Variables des espaces
$none  : 0;
$xs    : $g05;     // 8px
$s     : $g05 * 2; // 16px
$m     : $g05 * 3; // 24px
$l     : $g05 * 5; // 40px
$xl    : $g05 * 7; // 64px
$xxl   : $g05 * 9; // 72px

// Responsive 
$mobile-size : 650px;
$min-width-mobile : 335px;

//test


// Animation
$transition: 0.15s ease-in-out;

body {
    background-color: $light-bg;
}
