@import "../../common.scss";

.FooterBar {
    font-size: 0.8rem;
    width: 100%;
    nav {
        display: flex;
        gap: $g10;
        margin: 0 100px;
        justify-content: space-around;

        ul {
            text-decoration: none;
            padding: 0;

            a {
                text-decoration: none;
                color: initial;
            }
        }
    }

    @media (max-width: $mobile-size) {
        font-size: 0.7rem;
        nav {
            text-align: center;
            gap: $g05;
            flex-wrap: wrap;
            ul {
                white-space: nowrap;
                margin: 0;

                &:last-child {
                    margin-bottom: $g05;
                }
            }
        }
    }
}
