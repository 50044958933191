@import "../common.scss";
@import "../../node_modules/react-tabs/style/react-tabs.scss";

.ResultPage {
    margin: 0 48px;

    .rp--loader {
        margin-top: 100px;
        display: flex;
        justify-content: center;
    }

    .rp--breadcumb {
        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: initial;
        }
    }

    .rp--lemme {
        .rp--lemme-title {
            color: $secondary;
            display: flex;
            gap: $g05;
            .rp--signal-error {
                font-size: 12;
                border: none;
                background-color: $primary;
                color: white;
                border-radius: $border-radius;
                padding: $g05;
                transition: $transition;
                &:hover {
                    cursor: pointer;
                    background-color: $secondary;
                    transition: $transition;
                }
            }
            .rp--gram-class {
                font-weight: 400;
            }
        }

        .rp-associated-words {
            font-weight: 600;
            line-height: 1.5rem;
            a {
                text-decoration: none;
                color: initial;
                &:hover {
                    border-bottom: 4px solid $primary;
                    transition: 0.2s;
                }
                transition: 0.2s;
            }

            margin-bottom: $g15;
        }

        .react-tabs {
            margin-bottom: 65px;

            &__tab-list {
                padding: 0;
                margin: 0;
                border: none;
                display: flex;
                gap: $g05;
            }

            &__tab {
                transition: $transition;
                background: $primary;
                border-radius: 10px 10px 0 0;
                border: none;
                color: white;
                flex-grow: 1;
                bottom: 0;
                text-align: center;
                font-weight: 600;
                padding: $g10;
                &--selected {
                    color: $secondary;
                    background: white;
                    border: none;
                }
                &:focus:after {
                    display: none;
                }
            }
            .rp-tabpanel {
                border-radius: 0 0 10px 10px;
                padding-top: $g05;
                &.def,
                &.prov,
                &.dict,
                &.citat,
                &.exp,
                &.voir {
                    background-color: white;
                    ol {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                    }
                    ol > li {
                        padding: $g10;
                        &:nth-child(even) {
                            background-color: $primary-light;
                        }
                    }
                }
            }
        }
    }

    .rp--word-not-found {
        margin: 10vh 0;
        text-align: center;
    }

    .rp--ask-lemme-link {
        transition: $transition;
        color: $primary;
        &:hover {
            transition: $transition;
            color: $secondary;
            cursor: pointer;
        }
    }

    .btn-return {
        text-align: center;
    }

    .btn {
        width: 30%;
        height: 50px;
        border-radius: $border-radius;
        color: white;
        border: none;
        background-color: $primary;
        cursor: pointer;
    }

    @media (max-width: $mobile-size) {
        min-width: 80vw;
        .rp--lemme {

            
            .rp--lemme-title {
                flex-wrap: wrap;
            }

            .react-tabs {
                margin-bottom: 65px;
                &__tab-list {
                    justify-content: center;
                    gap: $g05/2;
                    flex-wrap: wrap;
                    li {
                        flex: 0 1 15%;
                        border-radius: $border-radius;
                    }
                    margin-bottom: $g05/2;
                }

                &__tab {
                    transition: $transition;
                    font-size: small;
                    font-weight: 400;
                    padding: $g05;
                }
            }
        }
    }
}
