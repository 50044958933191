@import "../../common.scss";

.Header {
    padding: 0 $l;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $dark-bg;
    max-width: 100%;
    height: 80px;
    min-width: $min-width-mobile;
    .header--title {
        a {
            display: block;
            width: 150px;
            font-weight: bolder;
            text-decoration: none;
            color: white;
        }
    }

    .header--search-bar {

            color: red;

        // flex-direction: ;
        // form {
        //     #champ {
        //         border-radius: $border-radius;
        //         border: none;
        //     }
        //     display: flex;
        //     gap: $g05;
        // }
    }

    @media (max-width: $mobile-size) {
        padding: 0;
        justify-content: space-around;
    }
}
