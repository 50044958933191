@import "../../common.scss";


.ButtonIcon {
    transition: transform 2s;
    border-radius: $border-radius;
    border-color: $primary;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $g10;

    &.primary{
        background-color: $primary;
        color: $text-primary;
        i.material-icons {
            color: white;
        }

        &:hover {
            background-color: $primary-hover;
            border-color: $primary-hover;
        }
    }
    &.secondary{
        background-color: transparent;
    }


    
    &:hover {
        cursor: pointer;
        transition: $transition;
    }
}