@import "../common.scss";
@import "../../node_modules/react-tabs/style/react-tabs.scss";

.LoginPage {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header {
        h1 {
            text-align: center;
            margin: 64px 0;
        }

        .lp-logo-content {
            text-align: center;
            #lp-logo {
                max-width: 70vw;
            }
        }
    }
    .lp-box {
        text-align: center;
        background-color: white;
        border-radius: $border-radius;
        padding: $g20;
        min-width: 50vw;
        .lp-box-form {
            margin-top: $g20;
            display: flex;
            flex-direction: column;
            gap: $g15;
            align-items: center;
            .lp-field{
                min-width: 30vw;
                border: none;
                border-bottom: 1px solid $primary ;
                transition: $transition;
                &:focus-visible {
                    outline: none;
                    box-shadow: 0 2px 0 $primary;
                    transition: $transition;
                }
            }

        }
    }
}
